<template>
  <div>
    <!-- <search-form ref="form" :model="form" :count="3" label-width="auto" :collapse="false" @search="searchHandle">
      <search-item label="" prop="personName">
        <el-input v-model.trim="form.personName" clearable placeholder="角色编号" />
      </search-item>
      <search-item label="" prop="mobel">
        <el-input v-model.trim="form.mobel" clearable placeholder="角色名称" />
      </search-item>
      <search-item label="" prop="mobel">
        <el-input v-model.trim="form.mobel" clearable placeholder="创建人姓名" />
      </search-item>
    </search-form> -->
    <Table
      ref="tableList"
      :loading="loading"
      :table-cols="tableCols"
      :table-data="tableData"
      class="table-wrap"
      :pagination="pagination"
      @sizeChange="sizeChange"
      @pageChange="pageChange"
    />
    <!-- <Drawer :drawer-visible.sync="drawerVisible" drawer-type="detail" :drawer-id="id" :company-info="companyInfo" /> -->
  </div>
</template>

<script>
// import { getDriverEscort, deleteDriverEscort, imporXls } from '@/api/driverEscort';
// import { exportByJson } from '@/utils';
// import Drawer from '@/views/system/user/drawer';
import { getSysTemPostList } from '@/api/systemUser';
export default {
  name: 'SystemRole',
  components: {
    // Drawer,
  },
  data() {
    return {
      loading: false,
      drawerVisible: false,
      companyInfo: {},
      id: 1,
      form: {
        dataType: 1, // 数据类型-0-驾驶员，1-押运员
        personName: null, // 驾驶员姓名
        mobel: null, // 联系电话
        dispatchId: null, // 关联调度Id
        enterpriseId: null, // 企业id
        enterpriseName: null, // 企业name
      },
      allDispatch: [],
      // tableBtns: [
      //   {
      //     label: '新增角色',
      //     icon: 'el-icon-circle-plus-outline',
      //     evt: () => {
      //       this.openDialog('add');
      //     },
      //   },
      //   {
      //     label: '删除',
      //     type: 'danger',
      //     evt: () => {
      //       this.delete();
      //     },
      //   },
      // ],
      tableCols: [
        { label: '角色编号', prop: 'postCode', fixed: 'left', type: 'jump', evt: (data) => { this.openDialog('detail', data); } },
        { label: '角色名称', prop: 'postName' },
        // { label: '备注', prop: 'remark' },
        // { label: '状态 ', prop: 'status' },
        // { label: '创建时间', prop: 'createDate', sortable: 'custom', headTimeSelect: true },
        // { label: '创建人', prop: 'updateBy' },
        // { label: '操作', type: 'operation', fixed: 'right',
        //   btns: [
        //     {
        //       label: '编辑',
        //       evt: (data) => {
        //         this.openDialog('edit', data);
        //       },
        //     },
        //   ],
        // },
      ],
      tableData: [],
      multipleSelection: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
    };
  },

  created() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const params = {
        id: 2, // 2auv 1货主 3承运商
        // ...this.form,
        // size: this.pagination.size,
        // current: this.pagination.page,
      };
      getSysTemPostList(params).then(res => {
        this.tableData = res || [];
        this.pagination = {
          page: res.current,
          size: res.size,
          total: res.length,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    searchHandle() {
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    openDialog(type, data) {

    },
    selectTime(time) {
    },
    delete() {
      // if (!this.multipleSelection.length) {
      //   this.$baseMessage('您还未勾选,请先勾选!', 'error');
      //   return;
      // }
      // const params = {
      //   idList: this.multipleSelection.map(d => d.id) || [],
      // };
      // deleteDriverEscort(params).then(res => {
      //   if (res) {
      //     this.$baseMessage('删除成功!', 'success');
      //     this.getList();
      //   }
      // });
    },
    imporXls() {
      // const params = {
      //   tpVehicles: this.multipleSelection,
      // };
      // imporXls(params).then(res => {
      //   if (res) {
      //     this.$baseMessage('导入成功!', 'success');
      //   }
      // });
    },
    onExport() {
      // const params = {
      //   ...this.form,
      // };
      // getDriverEscort(params).then(res => {
      //   const header = ['姓名', '关联调度', '联系电话', '身份证号码', '从业资格证有效期', '驾驶证有效期', '创建人', '创建时间', '更新人', '更新时间'];
      //   const exportData = res.records.map((item) => {
      //     return {
      //       personName: item.personName,
      //       dispatchName: item.dispatchName,
      //       mobel: item.mobel,
      //       idNumber: item.idNumber,
      //       createBy: item.createBy,
      //       createDate: item.createDate,
      //       updateBy: item.updateBy,
      //       updateDate: item.updateDate,
      //     };
      //   });
      //   exportByJson(exportData, header, '车辆列表.xlsx');
      // }).catch(() => {
      //   this.$baseMessage('导出失败', 'error');
      // });
    },
  },
};

</script>
